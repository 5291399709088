import Layout from '../layout/layout';
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { HeroHeader } from './common/HeroHeader';
import {
  WelcomeCenterData,
  Plan,
  WelcomeCenterTab,
  ResourceGrid,
} from './interfaces/welcome-center.interface';
import { PlanDropdown } from './common/PlanDropdown';
import { getPlansDropdown } from './common/PlanDropdownOptions';
import { SetupContent } from './common/SetupContent';
import { LinkCTA } from './common/LinkCTA';
import { ResourcesGrid } from './common/ResourcesGrid';
import { useStaticStrings } from '../../common/hooks/useStaticStrings';
import RenderPlanHeading from './common/PlansHeading';
import { BladeSubNav } from '../common/blades/BladeSubNav';
import { initialPlan } from './activation';
import { useCMSState } from '@internal/useCMSHook';
import { ContentfulBladeSubNav } from '../../contentful/content-types/blades/blade-sub-nav';
import { useConfiguration } from '../../common/hooks/useConfiguration';
import { MainFooter } from '../layout/refreshed-footer/footer.interfaces';
import { ContentfulNavNavigationGroup } from '../../contentful/content-types/nav-navigation-group';

export const query = graphql`
  query($locale: String!) {
    welcomeCenter(tabName: { eq: "core-setup" }, locale: { eq: $locale }) {
      ...welcomeCenterFragment
    }
    headerData: navNavigationGroup(
      contentfulId: { eq: "3hzSjT58plRWzuQrC4j4ny" }
      locale: { eq: $locale }
    ) {
      ...navNavigationGroupFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    subnav: bladeSubNav(contentfulId: { eq: "1e0eITYdkm5xNNEvoFpLzK" }, locale: { eq: $locale }) {
      ...subnavFragment
    }
    resourceGrid: bladeInfoBlocks(
      contentfulId: { eq: "5qZqpdha26WtvVPri2e2t7" }
      locale: { eq: $locale }
    ) {
      title
      relatedInfoBlocks {
        title
        description
        linkText
        linkUrl
      }
    }
  }
`;

const CoreSetup: React.FC<WelcomeCenterData> = ({ data, pageContext }) => {
  const config = useConfiguration();
  let { headerData, footerData, resourceGrid, subnav, welcomeCenter } = data;
  if (config.targetEnv === 'staging') {
    footerData = useCMSState<MainFooter>(footerData);
    headerData = useCMSState<ContentfulNavNavigationGroup>(headerData);
    subnav = useCMSState<ContentfulBladeSubNav>(subnav);
    welcomeCenter = useCMSState<WelcomeCenterTab>(welcomeCenter);
    resourceGrid = useCMSState<ResourceGrid>(resourceGrid);
  }
  const { mainHeader, subHeader, guideList } = welcomeCenter;
  const [currentPlan, setPlan] = useState<Plan>(initialPlan);
  const setupData = guideList.filter(
    item => item.metadata.plan.toLocaleLowerCase() === currentPlan.id.toLocaleLowerCase()
  );
  const staticStrings = useStaticStrings();

  const planType = staticStrings.json_values.Plan_type;

  const durationBadgesObj = staticStrings.json_values['DurationBadgeLevels'] as any;

  const plans = staticStrings.json_values.plans as any;
  const durationBadges = {
    free: durationBadgesObj.free,
    pro: durationBadgesObj.pro,
    business: durationBadgesObj.business,
  };

  return (
    <Layout headerData={headerData} pageContext={pageContext} footerData={footerData}>
      <Helmet>
        <title>Welcome Center | Cloudflare</title>
      </Helmet>

      <HeroHeader title={mainHeader} subtitle={subHeader}></HeroHeader>
      <BladeSubNav blade={subnav} pagePrefix={'/'} />

      <>
        <div className="flex flex-wrap mw9 center pt6-l pt4">
          <section className="w-20-l w-100 pl3-l pr6-l ph4">
            <h3 className="b mb2">{planType}</h3>
            <PlanDropdown
              options={getPlansDropdown(plans as any)}
              currentPlan={currentPlan}
              setPlan={setPlan}
            ></PlanDropdown>
          </section>

          <section className="w-60-l w-100 ph4">
            {RenderPlanHeading(currentPlan.id)}
            <SetupContent
              durationBadges={durationBadges}
              lists={setupData}
              introtext={welcomeCenter.introText}
              currentPlan={currentPlan}
            />

            <section className="flex flex-column flex-wrap mw8 center items-center pb7">
              <h2 className="w-100 f6 b center lh-title tc">{welcomeCenter.nextStepTitle}</h2>
              <LinkCTA url={welcomeCenter.nextStepButton.url}>
                {welcomeCenter.nextStepButton.text}
              </LinkCTA>
            </section>
          </section>
        </div>

        <ResourcesGrid
          resourceGrid={{
            title: resourceGrid.title,
            resources: resourceGrid.relatedInfoBlocks,
          }}
        ></ResourcesGrid>
      </>
    </Layout>
  );
};
export default CoreSetup;
